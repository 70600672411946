@import 'mixins';

.input-error{
    box-shadow: 0px 0px 3px 0px $red;
}

.main-brands{
    display: none !important;
}

.main-news{
    display: none !important;
}

.main-questions{
    display: none !important;
}

.broken-block{
    // width: calc(100% - 30px);
    width: 100%;
    border-radius: 10px;
    // padding: 15px;
    @include flexRow(center);
    // flex-wrap: wrap;
    color: white;
    background-color: #87a9c2;
    
    &__name{
        text-align: center;
        border-radius: 10px;
        background-color: $green;
        padding: 15px;
        margin: 10px 20px 10px 20px;
        font-weight: 500;

        &:first-child{
            @include flexColumn(center);
            align-items: center;
        }
    }
}

.warranty-section{
    width: 100%;
    height: 250px;
    // background-color: plum;
    // margin-top: 40px;
    padding: 30px 0px;
    position: relative;
    &::before{
        padding: 30px 0px;
        content: "";
        display: block;
        position: absolute;
        z-index: -1;
        left: -100vw;
        right: -100vw;
        // padding: 40px 0px 40px 0px;
        // width: 100%;
        top: 0;
        height: 250px;
        background-color: $main-subcolor;
        // background-color: black;
    }
    
    &__header{
        height: 30px;
        // background-color: orange;
        font-size: 24px;
        font-weight: 700;
        color: $text;
    }

    &__warranties-container{
        width: 100%;
        height: 200px;
        // background-color: orange;
        margin-top: 20px;
        @include flexRow(space-between);
        flex-wrap: wrap;
        align-items: center;
    }

    &__warranty{
        max-width: 420px;
        height: 200px;
        // background-color: pink;
        @include flexRow(flex-start);
        &:last-child{
            // margin-left: 40px
        }
    }

    &__content-block{
        @include flexColumn(flex-start);
        width: 260px;
        height: 100%;
        // background-color: plum;
    }

    &__warranty-header{
        font-size: 14px;
        font-weight: 500;
        // background-color: red;
        height: 40px;
    }

    &__warranty-text{
        font-size: 12px;
        margin-top: 30px;
    }

    &__img-block{
        width: 145px;
        height: 100%;
        margin-left: 15px;
        // background-color: plum;
    }
}

.scheme-section{
    width: 100%;
    // height: 250px;
    // background-color: plum;
    // margin-top: 40px;
    padding: 30px 0px;
    
    &__header{
        height: 30px;
        // background-color: orange;
        font-size: 24px;
        font-weight: 700;
        color: $text;
    }

    &__content-container{
        width: 100%;
        // height: 200px;
        // background-color: orange;
        margin-top: 20px;
        @include flexRow(flex-start);
        flex-wrap: wrap;
        align-items: center;
    }

    &__slider-container{
        width: 100%;
        height: 300px;
        // background-color: pink;
        @include flexRow(space-between);
        align-items: center;
    }

    &__slider-button{
        width: 30px;
        height: 30px;
        // background-color: plum;
        @include flexRow(center);
        align-items: center;
        cursor: pointer;
        svg{
            path{
                transition: 0.3s;
                fill: darken(white, 35%);
            }
        }
        &:hover{
            svg{
                path{
                    fill: black;
                }
            }
        }
    }
    
    &__slides-container{
        width: 860px;
        height: 260px;
        padding: 20px;
        background-color: #87a9c2;
        border-radius: 10px;
        @include flexColumn(flex-start);
        position: relative;
    }
    
    &__slide{
        width: calc(100% - 40px);
        height: calc(100% - 40px);
        position: absolute;
        background-color: #87a9c2;
        @include flexColumn(flex-start);
        // opacity: 0;
        // left: 0px;
        // width: relative;
        display: none;
        // background-color: red;
    }
    
    &__slide_active{
        @include flexColumn(flex-start);
        // z-index: 1;
        opacity: 1;
    }

    &__slide-header{
        color: white;
        font-size: 24px;
        font-weight: 500;
    }

    &__slide-content-block{
        width: 100%;
        height: 200px;
        // background-color: yellow;
        @include flexRow(space-between);
        // align-items: center;
        margin-top: 40px;
    }

    &__slide-text{
        color: white;
        max-width: 500px;
        // background-color: pink;
        font-size: 20px;
        font-weight: 300;
    }

    &__img-block{
        width: 200px;
        height: 200px;
        // background-color: pink;
        @include flexRow(center);
        // align-items: center;
        svg{
            path{
                fill: white;
            }
        }
    }

}

.error-line{
    // width: calc(100% - 40px);
    width: 100%;
    // background-color: red;
    margin-bottom: 20px;
    margin-top: 20px;
    color: white;
    // padding: 20px;
    border-radius: 10px;
    @include flexRow(center);
    
    &__img-block{
        width: 100px;
        height: 100px;
        background-color: $green;
        // background-color: red;
        // background-color: $main-color;
        // border-radius: 200px;
        border-radius: 10px;
        @include flexRow(center);
        align-items: center;
        
        svg{
            max-width: 70%;
            max-height: 70%;
        }
    }
    
    &__error{
        background-color: $green;
        width: calc(100% - 140px);
        min-height: 80px;
        padding: 10px;
        border-radius: 10px;
        @include flexRow(center);
        align-items: center;
        // background-color: $main-color;
        margin-left: 20px;
        strong{
            color: white;
            @include flexRow(center);
            align-items: center;
        }
    }

    &__separator{
        width: 4px;
        height: 40px;
        background-color: darken(white, 3%);
        content: "";
        display: block;
        margin: 0px 15px 0px 15px;
        border-radius: 10px;
    }
}

.question-content-block{
    // height: unset !important;
    // height: unset !important;
    // max-height: 480px;
    background-color: $green !important;
    height: unset !important;
    top: 0px;
    max-height: 300px;

    &__question-header{
        font-size: 30px;
        color: white;
        margin-bottom: 20px;
    }
    
    h2{
        width: calc(100% - 10px);
        padding: 0px 0px 0px 10px;
        overflow-y: scroll;
        width: 100%;
        height: 100%;
        // background-color: pink;
        font-size: 100%;
        font-size: 24px;
        line-height: 36px;
        // white-space: nowrap;
        &::-webkit-scrollbar{
            width: 3px;
        }
        &::-webkit-scrollbar-thumb{
            background-color: $main-color;
        }
    }
}

.article-block{
    &__separator{
        width: 100%;
        height: 2px;
        background-color: darken(white, 15%);
        margin-top: 10px;
        margin-bottom: 10px;
    }

    &__header{
        font-size: 18px;
        font-weight: 500;
        // color: ;
    }

    &__tags-block{
        @include flexRow(flex-start);
        flex-wrap: wrap;
    }

    &__tag{
        padding: 5px 5px 5px 10px;
        background-color: $blue-black;
        height: 20px;
        @include flexColumn(center);
        position: relative;
        z-index: 2;
        color: white;
        text-decoration: none;
        cursor: pointer;
        margin: 20px;
        &::after{
            position: absolute;
            display: block;
            content: "";
            // background-color: yellow;
            border: 15px solid transparent;
            border-left: 15px solid $blue-black;
            right: -30px;
            z-index: -1;
        }
        &:hover{
            background-color: $red;
            &::after{
                border: 15px solid transparent;
                border-left: 15px solid $red;
            }
        }
    }

    &__answer{
        border-radius: 0px;
    }
    &__tags-block{
        width: calc(100% - 20px);
        padding: 0px 10px 0px 10px;
    }
    
    &__tags-container{
        justify-content: center;
    }
    &__tag{
        height: unset;
        text-align: center;
        &::after{
            display: none;
        }
    }

}

.error-block{
    width: 100vw;
    height: 500px;
    // background-color: red;
    // margin-bottom: -200px;
    @include flexColumn(center);
    align-items: center;

    svg{
        width: 300px;
        height: 300px;
        path{
            fill: $red;
        }
    }

    &__message{
        font-size: 20px;
        @include flexRow(center);
        align-items: center;
        color: $red;
    }

    &__link{
        margin-top: 20px;
        color: $green;
        font-size: 24px;
        &:hover{
            text-decoration: none;
        }
    }
}

.answer-section{
    // background-color: red;
    width: 100%;
    @include flexColumn(flex-start);
    
    &__question{
        // margin-top: -40px;
        width: calc(100% - 40px);
        padding: 20px;
        border-radius: 10px;
        background-color: $red;
        @include flexColumn(flex-start);
        color: white;
    }

    &__answer{
        // margin-top: -40px;
        margin-top: 30px;
        width: calc(100% - 40px);
        padding: 20px;
        border-radius: 10px;
        background-color: #87a9c2;
        @include flexColumn(flex-start);
        color: white;
    }

    &__question-header{
        font-size: 30px;
    }

    &__question-text{
        font-size: 18px;
        width: calc(100% - 10px);
        padding: 0px 0px 0px 10px;
        line-height: 30px;
    }

    &__header{
        font-size: 30px;
        // color: black;
        // margin-bottom: 20px;
    }

    &__text{
        font-size: 18px;
        width: calc(100% - 10px);
        padding: 0px 0px 0px 10px;
        line-height: 30px;
    }

    &__tags-block{
        // background-color: yellow;
        @include flexColumn(flex-start);
        margin-top: 40px;
    }

    &__tags-header{
        font-size: 18px;
        font-weight: 500;
        // color: ;
        // background-color: pink;
    }
    
    &__tags-container{
        margin-top: 5px;
        // background-color: pink;
        @include flexRow(flex-start);
        flex-wrap: wrap;
    }

    &__tag{
        padding: 5px 5px 5px 10px;
        background-color: $blue-black;
        height: 20px;
        @include flexColumn(center);
        position: relative;
        z-index: 2;
        color: white;
        text-decoration: none;
        cursor: pointer;
        margin: 20px;
        &::after{
            position: absolute;
            display: block;
            content: "";
            // background-color: yellow;
            border: 15px solid transparent;
            border-left: 15px solid $blue-black;
            right: -30px;
            z-index: -1;
        }
        &:hover{
            background-color: $red;
            &::after{
                border: 15px solid transparent;
                border-left: 15px solid $red;
            }
        }
    }
}

.main-section{
    width: 100%;
    height: 480px;
    // background-color: red;
    // position: relative;
    @include flexRow(center);

    &__img-block{
        position: absolute;
        // background-color: yellow;
        width: 100%;
        height: 480px;
        display: none;
    }

    &__img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
    }

    &__img-cover{
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: $main-color;
        opacity: 30%;
    }

    &__container{
        max-width: 1360px;
        width: 100%;
        height: 100%;
        // background-color: yellow;
        // position: absolute;
        @include flexRow(space-between);
        align-items: center;
    }

    &__content-block{
        max-width: 910px;
        width: 100%;
        height: 300px;
        background-color: #87a9c2;
        @include flexColumn(flex-start);
        padding: 30px;
        box-shadow: 0 0 40px 10px transparentize($text-dark, 0.9);
        border-radius: 10px;
        // color: white;
    }

    &__form-block{
        width: 260px;
        height: 300px;
        // background-color: pink;
        background-color: $main-subcolor;
        // margin-top: 50px;
        padding: 30px;
        box-shadow: 0 0 40px 10px transparentize($text-dark, 0.9);
    }

    &__form-container{
        @include flexColumn(flex-start);
    }

    &__header{
        color: white;
        font-size: 42px;
        font-weight: 700;
        // margin-top: 120px;
        // height: 20px;
        // background-color: red;
    }

    &__text{
        color: white;
        font-size: 16px;
        font-weight: 500;
        // background-color: red;
        line-height: 30.08px;
        margin-top: 20px;
    }

    &__form{
        width: 100%;
        height: 100%;
        // background-color: plum;
        @include flexColumn(flex-start);
    }

    &__form-header{
        font-size: 16px;
        font-weight: 900;
        color: white;
        border-radius: 10px;
        padding: 10px;
        background-color: $red;
    }

    &__input-container{
        width: 100%;
        height: 75px;
        // background-color: orange;
        margin-top: 15px;
    }

    &__input-name{
        font-size: 14px;
        font-weight: 700;
    }

    &__input{
        margin-top: 8px;
        height: 48px;
        width: 218px;
        // background-color: red;
        padding: 0px 20px;
        border: 1px solid $main-light-gray;
        outline: none;
        font-size: 14px;
        font-weight: 700;
        transition: 0.3s;
        &:focus{
            // border-color: $red;
            box-shadow: 0px 0px 10px 0px $main-light-gray;
        }
    }

    &__button{
        margin-top: 15px;
        width: 100%;
        height: 50px;
        border: none;
        color: white;
        font-size: 14px;
        font-weight: 700;
        background-color: $main-color;
        cursor: pointer;
        transition: 0.3s;
        text-align: center;
        @include flexColumn(center);
        &:hover{
            background-color: darken($main-color, 5%);
        }
    }

    &__policy{
        width: 100%;
        height: 20px;
        // background-color: orange;
        margin-top: 15px;
        @include flexRow(flex-start);
        align-items: center;
    }

    &__policy-text{
        font-size: 11px;
        font-weight: 500;
        color: black;
    }

    &__policy-link{
        font-size: 11px;
        font-weight: 500;
        color: $main-color;
        margin-left: 10px;
        text-decoration: none;
        transition: 0.3s;
        &:hover{
            color: $red;
        }
    }

    &__form-message{
        // width: 100%;
        height: 200px;
        margin-top: 40px;
        // background-color: orange;
        text-align: center;
        @include flexColumn(center);
        font-size: 18px;
        font-weight: 500;
        color: black;
        display: none;
    }

    &__step{
        background-color: #87a9c2;
        box-shadow: 0 0 40px 10px transparentize($text-dark, 0.9);
        padding: 30px;
        border-radius: 10px;
    }

    &__step-form-header{
        color: white;
        font-weight: 500;
    }

    &__step-content-block{
        max-width: 970px;
        width: 100%;
        // height: 300px;
        // background-color: #87a9c2;
        // box-shadow: 0 0 40px 10px transparentize($text-dark, 0.9);
        @include flexRow(space-between);
        // padding: 30px;
        border-radius: 10px;
    }

    &__step-text-content-block{
        // background-color: pink;
        background-color: #87a9c2;
        box-shadow: 0 0 40px 10px transparentize($text-dark, 0.9);
        margin-left: 20px;
        max-width: 630px;
        width: 100%;
        padding: 30px;
        border-radius: 10px;
    }

    &__step-header{
        margin-top: 35px;
        color: white;
        font-size: 34px;
        font-weight: 700;
        // margin-top: 120px;
        // height: 20px;
        // max-width: 500px;
        // background-color: red;
    }
    
    &__step-text{
        color: white;
        font-size: 14px;
        font-weight: 500;
        // background-color: red;
        line-height: 24px;
        margin-top: 20px;
    }

    &__step-img-block{
        width: 260px;
        height: 300px;
        // background-color: pink;
        background-color: $main-subcolor;
        // margin-top: 50px;
        padding: 30px;
        // box-shadow: 0 0 40px 10px transparentize($text-dark, 0.9);
        @include flexColumn(center);
        align-items: center;
    }

    &__step-img{
        max-width: 100%;
        max-height: 100%;
    }

    .advice-header-block{
        text-align: center;
        @include flexColumn(center);
        align-items: center;
        font-size: 44px;
    }

    &__article-content-block{
        max-width: 910px;
        width: 100%;
        // height: 300px;
        background-color: #87a9c2;
        @include flexRow(space-between);
        padding: 30px;
        // height: calc(100% - 60px);
        height: 300px;
        box-shadow: 0 0 40px 10px transparentize($text-dark, 0.9);
        border-radius: 10px;
    }

    &__article-text-content-block{
        // background-color: pink;
        // margin-left: 40px;
        max-width: 610px;
        width: 100%;
    }

    &__article-header{
        // margin-top: 35px;
        color: white;
        font-size: 34px;
        font-weight: 700;
        // margin-top: 120px;
        // height: 20px;
        // max-width: 500px;
        // background-color: red;
    }

    &__article-stats-block{
        // width: 280px;
        // height: 100%;
        // background-color: pink;
        @include flexColumn(flex-end);
    }

    &__article-stat-line{
        margin-top: 10px;
        color: white;
        font-size: 14px;
        // background-color: red;
        @include flexRow(flex-start);
    }

    &__article-stat-name{
        width: 120px;
        // background-color: blue;
    }

    &__article-img-block{
        border-radius: 10px;
        width: 260px;
        height: 300px;
        // background-color: pink;
        background-color: $main-subcolor;
        // margin-top: 50px;
        padding: 30px;
        // box-shadow: 0 0 40px 10px transparentize($text-dark, 0.9);
        @include flexColumn(center);
        align-items: center;
    }

    &__article-img{
        max-width: 100%;
        border-radius: 10px;
        max-height: 100%;
    }
}

.breadcrumbs{
    // background-color: red;
    @include flexRow(flex-start);
    align-items: center;
    list-style: none;
    margin-bottom: 20px;
    padding: 20px;
    background-color: #87a9c2;
    margin-top: -40px;
    border-radius: 10px;

    &__item{
        text-align: center;
        &:last-child{
            pointer-events: none;
        }
        &:not(:first-child){
            margin-left: 5px;
        }
    }
    
    &__separator{
        margin: 0px 5px 0px 5px;
        color: white;
    }
    
    &__link{
        text-decoration: none;
        color: white;
        transition: 0.3s;
        &:hover{
            color: $red;
        }
    }
}

.errors-section{
    // padding: 60px 0px 0px 0px;
    // position: relative;
    width: 100%;
    // height: 200px;
    // background-color: red;
    @include flexColumn(flex-start);
    // border-radius: 10px;
    overflow: hidden;
    margin-top: 30px;

    &:nth-child(3){
        margin-top: 50px;
        margin-bottom: 50px;
    }

    &__header{
        width: calc(100% - 20px);
        padding: 0px 10px 0px 10px;
        // padding: 0px 10px 0px 10px;
        min-height: 50px;
        // background-color: yellow;
        color: $text;
        font-size: 36px;
        background-color: #87a9c2;
        @include flexColumn(center);
        color: white;
        border-radius: 5px 5px 0px 0px;
        
    }

    &__errors-container{
        width: 100%;
        // height: 200px;
        // background-color: yellow;
        // padding: 0px 10px 0px 10px;
        // margin-top: 10px;
        @include flexColumn(flex-start);
    }

    &__error{
        width: calc(100% - 20px);
        // width: 100%;
        // padding: 20px 10px 20px 10px;
        padding: 10px;
        // background-color: pink;
        @include flexRow(space-between);
        text-decoration: none;
        color: $text;
        // background-color: $main-subcolor;
        // border-bottom: 1px solid $text;
        font-size: 18px;
        border-radius: 10px;
        transition: 0.3s;
        &:hover{
            // background-color: darken($main-subcolor, 5%);
            background-color: $green;
        }
    }
    
    &__error-name{
        padding: 10px;
        width: calc(100% - 20px);
        border-radius: 10px;
        // background-color: red;
        @include flexColumn(center);
        // border-right: 1px solid $text;
    }
    &__error-name_price{
        width: 65%;
        background-color: darken($main-subcolor, 5%);
        // background-color: red;
        // border-right: 1px solid $text;
        @include flexColumn(center);
    }

    &__error-price{
        // width: 10%;
        // background-color: yellow;
        text-align: center;
        font-size: 15px;
        width: calc(10% - 20px);
        background-color: darken($main-subcolor, 5%);
        padding: 10px;
        border-radius: 10px;
        @include flexColumn(center);
    }
    
    &__error-time{
        @include flexColumn(center);
        font-size: 15px;
        // width: 10%;
        // background-color: yellow;
        text-align: center;
        width: calc(10% - 20px);
        background-color: darken($main-subcolor, 5%);
        padding: 10px;
        border-radius: 10px;
    }
    
    &__error-warranty{
        @include flexColumn(center);
        font-size: 15px;
        // width: 10%;
        // background-color: yellow;
        text-align: center;
        width: calc(10% - 20px);
        background-color: darken($main-subcolor, 5%);
        padding: 10px;
        border-radius: 10px;
    }

    &__first-line{
        width: calc(100% - 20px);
        padding: 20px 10px 20px 10px;
        // background-color: pink;
        @include flexRow(space-between);
        text-decoration: none;
        color: $text;
        background-color: darken($main-subcolor, 5%);
        // border-bottom: 1px solid $text;
        border-radius: 0px 0px 10px 10px;
        font-size: 18px;
        transition: 0.3s;
        &:hover{
            background-color: darken($main-subcolor, 5%);
        }
    }

    &__bar{
        width: 65%;
        // background-color: red;
        // border-right: 1px solid $text;
        // background-color: transparent;
        @include flexColumn(center);
    }

    &__line-name{
        @include flexColumn(center);
        font-size: 15px;
        width: 10%;
        // background-color: yellow;
        text-align: center;
        color: black;
    }
}



.cats-section{
    width: 100%;
    // height: 270px;
    // background-color: plum;
    @include flexColumn(flex-start);
    // margin-top: 60px;
    // padding: 60px 0px 0px 0px;
    position: relative;
    align-items: center;
    &::before{
        content: "";
        display: block;
        position: absolute;
        z-index: -1;
        left: -100vw;
        right: -100vw;
        // padding: 40px 0px 40px 0px;
        // width: 100%;
        top: 0;
        height: relative;
        background-color: $main-subcolor;
        // background-color: black;
    }

    &__cats-container{
        width: 100%;
        height: 225px;
        overflow: hidden;
        // background-color: $main-light-dark;
        // background-color: yellow;
        // margin-top: 20px;
        @include flexRow(center);
        align-items: center;
        flex-wrap: wrap;
    }

    &__cat{
        width: 250px;
        min-height: 70px;
        padding: 5px 20px 5px 20px;
        // margin: 0.5px;
        // border: 0.5px solid black;
        // background-color: aqua;
        @include flexRow(flex-start);
        align-items: center;
        text-align: center;
        margin: 15px;
        text-decoration: none;
        background-color: white;
        box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.123);
        font-size: 14px;
        transition: 0.3s;
        border-radius: 10px;
        &:hover{
            background-color: darken(white, 5%);
            .cats-section__cat-name{
                color: $red;
                border-color: $red;
            }
            // &:last-child{
            //     .cats-section__cat-name{
            //         color: $text;
            //         border-color: $text;
            //     }
            // }
        }
        // &:last-child{
        //     .cats-section__cat-name{
        //         color: $main-light-dark;
        //         border-color: $main-light-dark;
        //     }
        // }
    }

    &__img-block{
        width: 30px;
        height: 30px;
        // background-color: pink;
        // margin-left: 20px;
    }

    &__img{
        max-width: 100%;
        max-height: 100%;
    }
    
    &__cat-name{
        transition: 0.3s;
        color: $main-color;
        font-size: 16px;
        font-weight: 500;
        // border-bottom: 1px solid $main-color;
        margin-left: 20px;
    }

    &__all-cats-button{
        width: 290px;
        height: 80px;
        background-color: #87a9c2;
        border-radius: 10px;
        cursor: pointer;
        text-align: center;
        text-decoration: none;
        color: white;
        font-weight: 500;
        font-size: 18px;
        @include flexColumn(center);
        align-items: center;
        margin-top: 15px;
        transition: 0.3s;
        &:hover{
            background-color: darken(#87a9c2, 5%);
        }
    }
}

.brand-cats-section{
    width: 100%;
    // height: 270px;
    // background-color: plum;
    @include flexColumn(flex-start);
    // margin-top: 60px;
    padding: 60px 0px 0px 0px;

    &__header{
        font-size: 36px;
        font-weight: 700;
        color: $text;
        height: 44px;
        // background-color: orange;
    }

    &__cats-container{
        width: 100%;
        // height: 205px;
        // background-color: $main-light-dark;
        margin-top: 20px;
        // background-color: red;
        @include flexRow(flex-start);
        align-items: center;
        flex-wrap: wrap;
    }

    &__cat{
        width: 310px;
        height: 101px;
        // margin: 0.5px;
        // border: 0.5px solid black;
        // background-color: aqua;
        @include flexRow(center);
        align-items: center;
        text-align: center;
        margin: 5px 5px 5px 5px;
        box-shadow: 0px 0px 0px 0.5px $main-light-dark;
        text-decoration: none;
        background-color: white;
        transition: 0.3s;
        &:hover{
            background-color: darken(white, 5%);
            .cats-section__cat-name{
                color: $red;
                border-color: $red;
            }
        }
    }
    
    &__cat-name{
        transition: 0.3s;
        color: $main-color;
        font-size: 16px;
        font-weight: 500;
        border-bottom: 1px solid $main-color;
    }
}

.pluses-section{
    width: 100%;
    height: 135px;
    // background-color: plum;
    margin-top: 40px;
    @include flexRow(space-between);

    &__plus{
        width: 235px;
        height: 100%;
        // background-color: orange;
        @include flexColumn(flex-start);
    }

    &__img-block{
        width: 100%;
        height: 52px;
        // background-color: aqua;
        @include flexRow(center);
    }

    &__text{
        // background-color: aqua;
        margin-top: 20px;
        text-align: center;
        font-size: 16px;
        font-weight: 500;
        color: $text-dark;
    }
}

.call-section{
    width: 100%;
    height: 90px;
    // background-color: plum;
    margin-top: 15px;
    @include flexRow(flex-start);
    align-items: center;
    border-bottom: 2px solid $main-light-gray;
    padding: 0px 0px 30px 0px;

    &__img-block{
        width: 64px;
        height: 64px;
        background-color: $green;
        border-radius: 64px;
        @include flexRow(center);
        align-items: center;
    }

    &__text-block{
        // background-color: orange;
        @include flexRow(flex-start);
        align-items: center;
        margin-left: 15px;
    }

    &__link{
        color: $main-color;
        font-size: 24px;
        font-weight: 700;
        text-decoration: none;
        border-bottom: 1px solid $main-color;
        transition: 0.3s;
        &:hover{
            color: $red;
            border-color: $red;
        }
    }

    &__text{
        font-size: 24px;
        font-weight: 300;
        color: $text-dark;
    }
}

.features-section{
    width: 100%;
    height: 370px;
    // background-color: plum;
    margin-top: 40px;
    
    &__header{
        height: 30px;
        // background-color: orange;
        font-size: 24px;
        font-weight: 700;
        color: $text;
    }

    &__features-container{
        width: 100%;
        height: 320px;
        // background-color: orange;
        margin-top: 20px;
        @include flexRow(space-between);
        flex-wrap: wrap;
        align-items: center;
    }

    &__feature{
        width: 310px;
        height: 120px;
        // background-color: aqua;
        @include flexRow(flex-start);
        align-items: center;
        // margin: 10px;
    }
    
    &__number{
        // background-color: darkorchid;
        font-size: 24px;
        font-weight: 900;
        // width: 40px;
        height: 100%;
        // @include flexColumn(center);
        color: $main-color;
        @include flexRow(flex-start);
        align-items: center;
        &::after{
            content: "";
            display: block;
            height: 100px;
            width: 20px;
            // background-color: red;
            background-image: url(./../src/img/feature-after.svg);
            background-repeat: no-repeat;
            background-position: center;
        }
    }

    &__text{
        font-size: 15px;
        font-weight: 500;
        color: $text;
        margin-left: 10px;
    }

    &__strong{
        color: black;
    }
}

.orders-message{
    width: 100%;
    height: 46px;
    // background-color: plum;
    @include flexRow(flex-start);
    margin-top: 30px;

    &__message{
        width: 340px;
        height: 100%;
        background-color: darken(white, 5%);
        color: $red;
        font-size: 14px;
        font-weight: 700;
        text-align: center;
        @include flexColumn(center);
        border-radius: 3px;
    }
}

.steps-section{
    width: 100%;
    height: 440px;
    // background-color: plum;
    margin-top: 60px;
    @include flexColumn(flex-start);

    &__header{
        height: 30px;
        font-size: 24px;
        font-weight: 700;
        color: $text-dark;
        // background-color: orange;
    }

    &__up-text{
        font-size: 14px;
        font-weight: 700;
        color: black;
        margin-top: 25px;
        // background-color: orange;
        height: 20px;
    }

    &__steps-container{
        width: 100%;
        height: 345px;
        // background-color: orange;
        margin-top: 20px;
        @include flexColumn(flex-start);
    }

    &__step{
        width: 100%;
        @include flexRow(flex-start);
        border-bottom: 2px solid $main-light-gray;
        // background-color: aqua;
        padding: 35px 0px 25px 0px;
        &:first-child{
            padding: 0px 0px 25px 0px;
        }
        &:last-child{
            padding: 35px 0px 0px 0px;
            border: none;
        }
    }

    &__img-block{
        width: 64px;
        height: 64px;
        background-color: $main-color;
        border-radius: 64px;
        @include flexRow(center);
        align-items: center;
    }

    &__links-block{
        width: 200px;
        // background-color: darkgray;
        margin-left: 30px;
        @include flexColumn(flex-start);
        align-items: flex-start;
    }

    &__link{
        margin-top: 5px;
        text-decoration: none;
        border-bottom: 1px solid $main-color;
        font-size: 14px;
        font-weight: 700;
        color: $main-color;
        width: auto;
        transition: 0.3s;
        &:hover{
            color: $red;
            border-color: $red;
        }
    }

    &__link-text{
        margin-top: 5px;
        font-size: 14px;
        font-weight: 700;
        color: $text;
    }

    &__text-block{
        // background-color: darkgray;
        @include flexRow(flex-start);
        align-items: flex-start;
        margin-left: 20px;
        height: calc(100% - 5px);
        padding: 5px 0px 0px 0px;
        max-width: 650px;
        // height: 100%;
    }

    &__text-link{
        // background-color: orange;
        font-size: 14px;
        font-weight: 700;
        color: $main-color;
        text-decoration: none;
        border-bottom: 1px solid $main-color;
        transition: 0.3s;
        &:hover{
            color: $red;
            border-color: $red;
        }
    }

    &__text{
        font-size: 14px;
        font-weight: 700;
        color: $text;
        &:nth-child(2){
            margin: 0px 4px 0px 4px;
        }
    }

    &__strong{
        color: black;
        font-weight: 800;
        font-size: 14px;
    }

    &__mar{
        margin: 0px 2px 0px 2px;
    }
}

.reviews-section{
    width: 100%;
    height: 300px;
    margin-top: 40px;
    padding: 30px 0px;
    // background-color: plum;
    @include flexColumn(flex-start);
    position: relative;
    &::before{
        content: "";
        display: block;
        position: absolute;
        z-index: -1;
        left: -100vw;
        right: -100vw;
        // width: 100%;
        top: 0;
        height: 100%;
        background-color: $main-subcolor;
        // background-color: black;
    }

    &__header{
        font-size: 24px;
        font-weight: 7000;
        color: $text;
    }

    &__reviews-container{
        width: 100%;
        height: 155px;
        // background-color: orange;
        margin-top: 30px;
        @include flexRow(space-between);
    }

    &__review{
        width: 480px;
        height: 155px;
        // background-color: aqua;
        @include flexColumn(flex-start);
    }

    &__date-block{
        width: 100%;
        height: 15px;
        // background-color: darkgray;
        @include flexRow(flex-start);
        align-items: center;
        font-size: 12px;
        font-weight: 600;
        color: black;
    }

    &__month{
        margin: 0px 4px;
    }

    &__author-name{
        width: 100%;
        height: 20px;
        margin-top: 10px;
        // background-color: darkgray;
        font-size: 16px;
        font-weight: 700;
        color: $green;
    }

    &__text{
        height: 100px;
        width: 100%;
        margin-top: 10px;
        // background-color: darkgray;
        font-size: 14px;
        font-weight: 500;
        color: $text;
        overflow: hidden;
        // box-shadow: 0px -7px 0px 0px black inset;
    }

    &__button{
        width: 130px;
        height: 40px;
        background-color: darken($text-dark, 15%);
        color: white;
        font-size: 12px;
        font-weight: 700;
        text-align: center;
        @include flexColumn(center);
        text-decoration: none;
        margin-top: 25px;
        border-radius: 3px;
        transition: 0.3s;
        &:hover{
            background-color: transparentize($text-dark, 0.2);
        }
    }
}

.notice-section{
    width: 100%;
    height: 130px;
    // background-color: plum;
    @include flexColumn(flex-start);
    margin-top: 40px;

    &__header{
        height: 30px;
        // background-color: orange;
        font-size: 24px;
        font-weight: 700;
        color: $text-dark;
    }

    &__text{
        // background-color: orange;
        font-size: 14px;
        font-weight: 500;
        color: $text;
        margin-top: 20px;
        line-height: 26px;
        height: 80px;
    }
}

.reasons-section{
    width: 100%;
    // background-color: plum;
    @include flexColumn(flex-start);
    margin-top: 30px;

    &__header{
        font-size: 30px;
        font-weight: 700;
        // background-color: orange;
        color: $text;
        height: 40px;
    }

    &__list{
        // background-color: orange;
        width: calc(100% - 45px);
        margin-top: 15px;
        padding: 0px 0px 0px 45px;
        list-style: numeric;
    }

    &__item{
        font-size: 14px;
        font-weight: 500;
        color: $text;
        line-height: 26px;
        &:not(:first-child){
            margin-top: 5px;
        }
    }

    &__text{
        // background-color: orange;
        margin-top: 15px;
        line-height: 26px;
        color: $text;
        font-size: 14px;
        font-weight: 500;
    }
}

.article{
    width: 100%;
    height: 400px;
    // background-color: red;
}

.geo-section{
    width: 100%;
    // height: 500px;
    // background-color: red;
    padding: 20px 0px;
    @include flexColumn(flex-start);

    &__header{
        font-size: 30px;
        font-weight: 700;
        // background-color: orange;
        color: $text;
        height: 40px;
    }

    &__container{
        width: 100%;
        // background-color: yellow;
        margin-top: 20px;
    }

    &__container-header{
        font-size: 18px;
        font-weight: 700;
        // background-color: orange;
        color: $text;
    }

    &__buttons-container{
        width: 100%;
        @include flexRow(flex-start);
        flex-wrap: wrap;
        // align-items: center;
        // background-color: pink;
        margin-top: 20px;
    }

    &__area-button{
        padding: 10px;
        background-color: $main-subcolor;
        margin-right: 10px;
        margin-top: 5px;
        margin-bottom: 5px;
        border-radius: 3px;
        cursor: pointer;
        transition: 0.3s;
        &:hover{
            background-color: darken($main-subcolor, 5%);
        }
    }

    &__area-button_img{
        padding: 10px;
        background-color: $main-subcolor;
        margin-right: 10px;
        margin-top: 5px;
        margin-bottom: 5px;
        border-radius: 3px;
        @include flexRow(center);
        align-items: center;
        cursor: pointer;
        transition: 0.3s;
        &:hover{
            background-color: darken($main-subcolor, 5%);
        }
    }

    &__area-img{
        width: 30px;
        height: 22px;
    }

    &__areas-block{
        @include flexColumn(flex-start);
        // background-color: pink;
        margin-top: 20px;
        display: none;
    }

    &__areas-header{
        font-size: 18px;
        font-weight: 700;
        // background-color: orange;
        color: $text;
    }

    &__areas-container{
        width: 100%;
        @include flexRow(center);
        flex-wrap: wrap;
        // background-color: plum;
    }
    
    &__link{
        margin: 15px;
        transition: 0.3s;
        color: $main-color;
        padding: 10px;
        background-color: $main-subcolor;
        border-radius: 3px;
        &:hover{
            text-decoration: none;
            background-color: darken($main-subcolor, 5%);
            // text-decoration: underline;
        }
    }
    
    &__link-name{
        color: black;
        color: $main-color;
    }
}

@media (max-width: 1360px){
    main{
        // display: none;
    }

    .common-section{
        @include flexColumn(flex-start);
    }

    .main-section{
        // display: none;
        // min-height: 300px;
        height: unset;
        // background-color: red;

        &__container{
            @include flexRow(center);
            flex-wrap: wrap;
            align-items: center;
        }

        &__content-block{
            // background-color: pink;
            max-width: unset;
            width: 100%;
            border-radius: 0px;
            height: unset;
            margin-top: 0px;
        }

        &__header{
            // background-color: pink;
            text-align: center;
            font-size: 28px;
        }

        &__text{
            font-size: 14px;
            text-align: center;
        }

        &__form-block{
            width: 100%;
            height: unset;
            // background-color: red;
            padding: 20px 0px 20px 0px;
        }

        &__form-header{
            width: 100%;
            text-align: center;
            // margin-bottom: 20px;
            // background-color: plum;
        }
        
        &__form-container{
            @include flexRow(center);
            flex-wrap: wrap;
            // background-color: pink;
        }

        &__input-container{
            width: unset;
            margin: 15px;
        }

        &__policy{
            // background-color: plum;
            @include flexRow(center);
        }

        &__step-content-block{
            // background-color: yellow;
            @include flexColumn(flex-start);
            max-width: unset;
            // flex-wrap: wrap;
        }

        &__step{
            order: 2;
            border-radius: 0px;
            box-shadow: none;
            background-color: #f0f6f9;
            padding: 30px;
            box-shadow: 0 0 40px 10px rgb(45 50 68 / 10%);
        }

        &__step-form-header{
            width: 100%;
            text-align: center;
            color: $red;
            // margin-bottom: 20px;
            // background-color: plum;
        }

        &__step-text-content-block{
            max-width: unset;
            width: calc(100% - 60px);
            order: 1;
            margin-left: 0px;
            border-radius: 0px;
        }

        &__step-header{
            text-align: center;
        }

        &__step-text{
            text-align: center;
        }

        &__step-img-block{
            width: 260px;
            height: 260px;
            background-color: transparent;
        }

        &__article-content-block{
            @include flexRow(center);
            flex-wrap: wrap;
            height: unset;
            border-radius: 0px;
            max-width: unset;
            width: 100%;
        }

        &__article-header{
            text-align: center;
            margin-top: 0px;
        }

        &__article-stats-block{
            margin-top: 20px;
        }
    }

    .breadcrumbs{
        margin-bottom: 0px;
        margin-top: 0px;
        @include flexRow(center);
        flex-wrap: wrap;
        border-radius: 0px;

        &__link{
            font-size: 14px;
        }
    }

    .brand-cats-section{
        // display: none;
        &__header{
            height: unset;
        }
    }

    .cats-section{
        margin-top: 40px;
        // display: none;
        // background-color: yellow;

        &__cats-container{
            height: 435px;
        }
    }

    .main-brands{
        display: flex !important;
        height: unset !important;
        max-height: 560px !important;
    }
    
    .main-news{
        display: block !important;
    }
    
    .main-questions{
        display: block !important;
    }

    .pluses-section{
        // display: none;
        // background-color: red;
        height: unset;
        @include flexRow(center);
        flex-wrap: wrap;

        &__plus{
            margin: 20px;
        }
    }

    .call-section{
        // display: none;
        // background-color: red;
        @include flexRow(center);
        flex-wrap: wrap;
        height: unset;

        &__img-block{
            margin: 20px;
        }

        &__text-block{
            margin-left: 0px;
            @include flexRow(center);
            flex-wrap: wrap;
            text-align: center;
        }

        &__link{
            margin: 10px;
        }

        &__text{
            margin: 10px;
        }
    }

    .features-section{
        // display: none;
        // background-color: red;
        height: unset;

        &__header{
            text-align: center;
        }

        &__features-container{
            height: unset;
            @include flexRow(center);
            flex-wrap: wrap;
        }

        &__feature{
            // background-color: yellow;
            width: 300px;
            margin: 10px;
        }
    }

    .orders-message{
        // display: none;
        &__message{
            width: 100%;
            border-radius: 0px;
        }
    }

    .errors-section{
        // display: none;
        // background-color: red;

        &__header{
            padding: 15px 10px 15px 10px;
            border-radius: 0px;
            text-align: center;
            font-size: 20px;
            // width: calc(100% - 30px);
        }

        &__errors-container{
            // background-color: yellow;
        }

        &__first-line{
            // background-color: pink;
            @include flexColumn(flex-start);
        }

        &__bar{
            width: 100%;
            // background-color: plum;
            text-align: center;
            border-bottom: 1px solid $main-light-gray;
        }

        &__line-name{
            width: 100%;
            // background-color: plum;
            text-align: center;
            border-bottom: 1px solid $main-light-gray;
            &:not(:first-child){
                margin-top: 5px;
            }
        }

        &__error{
            // background-color: pink;
            @include flexColumn(flex-start);
            padding: 10px;
        }

        &__error-name{
            // background-color: plum;
            border-right: none;
            width: 100%;
            text-align: center;
            margin-top: 5px;
        }

        &__error-price{
            // background-color: plum;
            border-right: none;
            width: 100%;
            text-align: center;
            margin-top: 5px;
        }

        &__error-time{
            // background-color: plum;
            border-right: none;
            width: 100%;
            text-align: center;
            margin-top: 5px;
        }

        &__error-warranty{
            // background-color: plum;
            border-right: none;
            width: 100%;
            text-align: center;
            margin-top: 5px;
        }
    }

    .error-line{
        margin-top: 0px;
        &__img-block{
            display: none;
        }

        &__error{
            margin-left: 0px;
            width: 100%;
            border-radius: 0px;
            strong{
                text-align: center;
                @include flexColumn(flex-start);
            }
        }

        &__separator{
            width: 100%;
            height: 5px;
            background-color: transparent;
            // display: none;
        }
    }

    .answer-section{
        // display: none;

        &__answer,
        &__question{
            border-radius: 0px;
        }

        &__tags-block{
            width: calc(100% - 20px);
            padding: 0px 10px 0px 10px;
        }
        
        &__tags-container{
            justify-content: center;
        }

        &__tag{
            height: unset;
            text-align: center;

            &::after{
                display: none;
            }
        }
    }

    .steps-section{
        // display: none;
        // background-color: red;
        height: unset;

        &__header{
            text-align: center;
        }

        &__up-text{
            text-align: center;
        }

        &__steps-container{
            // background-color: yellow;
            height: unset;
        }

        &__step{
            @include flexRow(center);
            flex-wrap: wrap;
            align-items: center;
        }

        &__img-block{
            margin: 20px;
            // background-color: pink;
        }

        &__links-block{
            // background-color: pink;
            text-align: center;
            margin: 20px;
            align-items: center;
        }

        &__text-block{
            @include flexRow(center);
            flex-wrap: wrap;
            text-align: center;
            margin: 0px;
        }
    }

    .reviews-section{
        // display: none;
        background-color: #d7e4ee;
        height: unset;
        align-items: center;
        &::before{
            display: none;
        }

        &__header{
            text-align: center;
        }

        &__reviews-container{
            @include flexRow(center);
            flex-wrap: wrap;
            height: unset;
            // background-color: yellow;
        }

        &__review{
            width: calc(100% - 20px);
            padding: 0px 10px 0px 10px;
        }
    }

    .geo-section{
        // display: none;
        // background-color: red;

        &__header{
            // background-color: yellow;
            text-align: center;
        }
        
        &__container{
            // background-color: yellow;
        }

        &__container-header{
            text-align: center;
        }

        &__buttons-container{
            // background-color: pink;
            @include flexRow(center);
            flex-wrap: wrap;
        }

        &__areas-block{
            // background-color: pink;
        }

        &__areas-header{
            text-align: center;
        }

        &__areas-container{
            // background-color: plum;
            @include flexRow(center);
            flex-wrap: wrap;
        }

        &__link{
            margin: 10px;
        }
    }

    .scheme-section{
        // display: none;
        // background-color: red;

        &__header{
            text-align: center;
        }

        &__content-container{
            // background-color: yellow;
        }

        &__slider-container{
            margin-top: 20px;
        }
        
        &__slider-button{
            display: none;
        }
        
        &__slides-container{
            border-radius: 0px;
            width: 100%;
            // background-color: pink;
            height: 300px;
        }

        &__content-block{
            height: unset;
            @include flexRow(center);
            flex-wrap: wrap;
        }

        &__slide-header{
            text-align: center;
        }

        &__slide-text{
            max-width: unset;
            width: 100%;
            text-align: center;
        }

        &__img-block{
            display: none;
        }
    }

    .warranty-section{
        // display: none;
        // background-color: red;
        height: unset;

        &::before{
            display: none;
        }

        &__header{
            text-align: center;
        }

        &__warranties-container{
            // background-color: yellow;
            @include flexRow(center);
            flex-wrap: wrap;
            height: unset;
            margin-top: 40px;
        }

        &__warranty{
            // background-color: pink;
            @include flexRow(center);
            flex-wrap: wrap;
            height: unset;
            margin: 20px;
        }

        &__content-block{
            margin: 10px;
        }

        &__img-block{
            margin: 10px;
        }
    }
}
